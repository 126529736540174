.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// ul {
//   display: flex;
//   margin: 0;
//   padding: 0;
//   list-style-type: none;
// }

// ul li {
//   margin: 0 2em;
//   text-transform: uppercase;
//   font-size: 16px;
// }

// li {
//   transition: all 0.5s ease-in-out;
// }

// li:hover {
//   color: brown;
// }

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.o-navigation__fullscreen {
  transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1) 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  .flexbox();
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 98;
  &.-d-none {
    display: none;
  }
}

.o-navigation__wrapper {
  .flexbox();
  justify-content: center;
  position: absolute;
  background: transparent;
  width: 100%;
  z-index: 99;
  padding: 25px 0;
  .m-navigation__content {
    .flexbox();
    width: 100%;
    max-width: 960px;
    padding: 0 25px;
    color: #fff;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    &.-alternate {
      h2 {
        color: #20274d;
      }
    }
    h2 {
      cursor: pointer;
      text-transform: lowercase;
      color: #fff;
      font-family: "Cormorant";
      font-size: 24px;
      font-weight: 100;

      @media (min-width: 768px) {
        font-size: 36px;
      }
    }

    .o-menuItemsInline__wrapper {
      .flexbox();
      margin: 0;
      padding: 0;
      flex-wrap: nowrap;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

.m-menuItems__content {
  .flexbox();
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.-alternate {
    li {
      color: #20274d;

      &:hover {
        color: #62698b;
      }
    }

    @media (max-width: 991px) {
      color: #fff;
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  li {
    width: 100%;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 50px;
    &:hover {
      color: antiquewhite;
    }

    @media (max-width: 991px) {
      margin-left: 0;
      font-size: 14px;
      margin-bottom: 50px;
    }
  }
}

@primary-color: #20274d;