@font-face {
  font-family: HKGrotesk;
  src: url(./fonts/HKGrotesk/HKGrotesk-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: HKGrotesk;
  src: url(./fonts/HKGrotesk/HKGrotesk-Bold.ttf) format('truetype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: HKGrotesk;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-family: 'Cormorant';
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  font-size: 64px;
  line-height: 1;
}

h4 {
  font-family: 'Cormorant';
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  font-size: 48px;
  line-height: 1;
}

p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.6;
}

a:hover {
  border-bottom: 1px solid #4C273E;
  text-decoration: none;
}

@media(max-width: 992px) {
  h2 {
    text-align: center;
    font-size: 48px;
  }

  h4 {
    text-align: center;
    font-size: 36px;
  }

  p {
    font-size: 16px;
  }
}

@media(max-width: 576px) {
  h2 {
    font-size: 36px;
  }

  h4 {
    font-size: 24px;
  }

  input, select {
    font-size: 12px;
  }
}
@primary-color: #20274d;