.o-faq__wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  .o-faq__container {
    width: 100%;
    max-width: 960px;
    padding: 50px 20px;
    h5 {
      font-family: "Cormorant";
      font-size: 42px;
      text-transform: capitalize;
      text-align: center;
      margin: 0 0 40px 0;
      @media (max-width: 768px) {
        text-align: left;
      }
    }
    .o-faq__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .m-faq__item {
        margin: 20px 0;
        width: 30%;

        @media (max-width: 768px) {
          width: 100%;
        }

        h6 {
          text-transform: capitalize;
          font-weight: 700;
          font-size: 24px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}

@primary-color: #20274d;