.o-services__header {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 150px 20px 85px 20px;
  article {
    margin: 0px;
    max-width: 720px;
    width: 100%;
    text-align: center;

    h3 {
      font-family: "Cormorant";
      font-size: 48px;
      line-height: 72px;
      margin: 0;
    }

    p {
      font-size: 14px;
      line-height: 36px;
      font-weight: normal;
    }
  }
}

.o-services__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  &.-bg-red {
    background-color: #4c273e;
    color: #fff !important;
  }
  .o-services__container {
    width: 100%;
    max-width: 960px;
    min-height: 300px;
    padding: 0 20px 0 20px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  @media (max-width: 991px) {
    padding: 0;
  }
}

.o-serviceCard__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &.-reverse {
    .o-serviceCard__container {
      &:last-child {
        order: -1;

        @media (max-width: 768px) {
          order: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .o-serviceCard__container {
      &:last-child {
        order: -1;
      }
    }
    &.-reverse {
      .o-serviceCard__container {
        &:last-child {
          order: -1;
        }
      }
    }
  }
  .o-serviceCard__container {
    width: 50%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 768px) {
      min-height: 300px;
      width: 100%;
    }

    article {
      text-align: left;
      max-width: 480px;
      width: 80%;
      padding: 0;

      h4 {
        text-align: left;
        font-family: HKGrotesk;
        font-size: 36px;
        font-weight: bold;
        line-height: 48px;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          text-align: center;
          font-size: 28px;
          line-height: 40px;
          margin: 0;
        }

        @media (max-width: 425px) {
          font-size: 20px;
          line-height: 32px;
        }
      }

      p {
        font-size: 14px;
        line-height: 28px;
        font-weight: normal;
        color: #000;

        @media (max-width: 768px) {
          text-align: center;
          margin: 10px 0 0 0;
          line-height: 28px;
        }

        @media (max-width: 425px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

@primary-color: #20274d;