.about .about-images {
  min-width: 720px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 720px;
}

.image-container-xl {
  height: 720px;
  width: 720px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.image-tile-xl {
  width: 336px;
}

.tile-md-xl {
  background-color: bisque;
  height: 288px;
}

.tile-lg-xl {
  background-color: beige;
  height: 384px;
}

.image-container-md {
  height: 470px;
  width: 480px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.image-tile-md {
  width: 216px;
}

.tile-md-md {
  background-color: bisque;
  height: 188px;
}

.tile-lg-md {
  background-color: beige;
  height: 248px;
}

.image-container-xs {
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.image-tile-xs {
  width: 140px;
}

.tile-md-xs {
  background-color: bisque;
  height: 120px;
}

.tile-lg-xs {
  background-color: beige;
  height: 155px;
}

@primary-color: #20274d;